import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import {ShimmerText, ShimmerThumbnail} from "react-shimmer-effects";
import ProdutoEstoqueUiComponent from "../components/ui/produtoEstoque.ui.component";
import {ProdutoService} from "../services/produto.service";


class Pesquisar extends React.Component {

    state = {
        loading:false,
        pesquisarTermo:'',
        resultadoPesquisaList:[],
        totalEncontradoPesquisa:0,
        categoriasList:[],
        categoriasSelecionadasList:[],
        limit:50,
        offset:0,
        totalPages:0
    }
    constructor(props) {
        super(props);

        this.submitSearch = this.submitSearch.bind(this)
        this.pesquisarTermo = this.pesquisarTermo.bind(this)
        this.handleCheckCategoria = this.handleCheckCategoria.bind(this)
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);
        let q = urlParams.get('q');
        if(q){
            this.state.pesquisarTermo=q
            this.pesquisarTermo()
        }


    }

    handleCheckCategoria(event,categoria){

        let idxCategoria = this.state.categoriasSelecionadasList.indexOf(categoria.id);
        let categoriaChecked = idxCategoria>-1;

        if(categoriaChecked){
            this.state.categoriasSelecionadasList.splice(idxCategoria,1)

        }else{
            this.state.categoriasSelecionadasList.push(categoria.id)
        }
        this.setState({refresh:true})

        console.log(this.state.categoriasSelecionadasList)
    }

    submitSearch($evt){
        if($evt.keyCode === 13 && this.state.pesquisarTermo){
            this.pesquisarTermo()
        }
    }


    pesquisarTermo(appendResult=false){

        if(this.state.pesquisarTermo){
            var searchParams = new URLSearchParams(window.location.search)
            searchParams.set("q", this.state.pesquisarTermo);
            var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);

            this.setState({loading:true})


            ProdutoService.searchProdutoEstoqueByTermo(this.state.pesquisarTermo,this.state.categoriasSelecionadasList,this.state.limit,this.state.offset).then((resultPage)=>{
                console.log(resultPage)

                let resultadoPesquisaList =  resultPage.rows;

                if(appendResult){
                    resultadoPesquisaList = this.state.resultadoPesquisaList.concat(resultPage.rows)
                }

                let state1 = {
                    totalEncontradoPesquisa:resultPage.count,
                    resultadoPesquisaList: resultadoPesquisaList,
                    totalPages:resultPage.totalPages,
                    categoriasList:resultPage.countCategorias
                };
                console.log(state1)
                this.setState(state1)

            }).finally(()=>{
                this.setState({loading:false})
            })


        }

    }

    render() {
        return (
            <Layout>
                <Seo title={this.state.pesquisarTermo?'Pesquisando por '+this.state.pesquisarTermo:'pesquisando...'} />

                <div className="product-area pb-60">
                    <div className="container">
                        <div className="row">
                            <div className="input-group">
                                <input type="text" disabled={this.state.loading} className="form-control" placeholder="Pesquisar..."
                                       value={this.state.pesquisarTermo} onKeyDown={(event)=>this.submitSearch(event)} onChange={(event)=> this.setState({pesquisarTermo:event.target.value}) } />
                                <span className="input-group-btn">
                                        <button className="btn btn-default" disabled={this.state.loading} type="button" onClick={this.pesquisarTermo}> <i className='fa fa-search'/>   </button>
                                      </span>
                            </div>

                            <div className='row'>

                            </div>
                            <div className="col-md-3 col-sm-12 mt-30">
                                <div className="shop-sidebar ">
                                    <h3 className="sidebar-title">Categorias</h3>
                                    <ul className="cat">
                                        {
                                            this.state.loading && (
                                                <>
                                                    <li>
                                                        <ShimmerText line={1} gap={10} />
                                                    </li>
                                                    <li>
                                                        <ShimmerText line={1} gap={10} />
                                                    </li>
                                                    <li>
                                                        <ShimmerText line={1} gap={10} />
                                                    </li>
                                                    <li>
                                                        <ShimmerText line={1} gap={10} />
                                                    </li>
                                                </>
                                            )
                                        }

                                        {
                                            this.state.loading===false && this.state.categoriasList && this.state.categoriasList.length>0 &&
                                            this.state.categoriasList.map((categoria, idx)=>(
                                                <li key={idx}>
                                                    <label htmlFor={'check'+idx}>
                                                        <input id={'check'+idx} type="checkbox" checked={this.state.categoriasSelecionadasList.indexOf(categoria.id)>-1} onChange={(event)=>this.handleCheckCategoria(event,categoria)} />
                                                        <span className='ml-10 text-unselectable'>{categoria.nome} ({categoria.countResults})</span>
                                                    </label>

                                                </li>
                                            ))
                                        }

                                    </ul>
                                </div>
                                <div className="shop-sidebar hide">
                                    <h3 className="sidebar-title">Filter by Price</h3>
                                    <div className="price-filter">
                                        <div id="slider-range"
                                             className="ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                                            <div className="ui-slider-range ui-corner-all ui-widget-header" style={{left: '15%', width: '45%'}}></div>
                                            <span tabIndex="0"
                                                  className="ui-slider-handle ui-corner-all ui-state-default"
                                                  style={{left: '15%'}}></span><span tabIndex="0" className="ui-slider-handle ui-corner-all ui-state-default"
                                                                                  style={{left: '60%'}}></span></div>
                                        <input type="text" id="amount" />
                                    </div>
                                </div>

                                <div className="shop-sidebar hide">
                                    <h3 className="sidebar-title">Best Product</h3>
                                    <div className="product-sidebar">
                                        <ul className="popular-product">
                                            <li>
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi
                                                            Product Title</a></h3>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt=""/></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="shop-sidebar hide">
                                    <h3 className="sidebar-title">Popular Tags</h3>
                                    <ul className="tag">
                                        <li><a href="#">Medifine</a></li>
                                        <li><a href="#">Cardio</a></li>
                                        <li><a href="#">Help</a></li>
                                        <li><a href="#">Blue</a></li>
                                        <li><a href="#">Medicine</a></li>
                                        <li><a href="#">Hopistal</a></li>
                                        <li><a href="#">Treatment</a></li>
                                        <li><a href="#">HTML</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-9 col-sm-12 mt-30">
                                <div className="row mb-40">
                                    <div className="col-md-6 col-sm-5 col-xs-9">
                                        <div className={(this.state.loading || this.state.resultadoPesquisaList.length===0?'hide':'')+' found'}>
                                            <p>Encontramos <span>{this.state.totalEncontradoPesquisa} produto(s)</span> em sua pesquisa</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-7 col-xs-3">
                                        <div className="shop-tab-menu f-right hide">
                                            <ul className="custom-shop-tab" role="tablist">
                                                <li role="presentation" className="active"><a href="#home"
                                                                                              aria-controls="home"
                                                                                              role="tab"
                                                                                              data-toggle="tab"><i
                                                    className="fa fa-th" aria-hidden="true"></i></a></li>
                                                <li role="presentation"><a href="#profile" aria-controls="profile"
                                                                           role="tab" data-toggle="tab"><i
                                                    className="fa fa-th-list" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>

                                        <div className="select-option f-right hidden-xs hide">
                                            <div className="btn-group bootstrap-select">
                                                <button type="button" className="btn dropdown-toggle btn-default"
                                                        data-toggle="dropdown" role="button" title="Sort By"><span
                                                    className="filter-option pull-left">Sort By</span>&nbsp;<span
                                                    className="bs-caret"><span className="caret"></span></span></button>
                                                <div className="dropdown-menu open" role="combobox">
                                                    <ul className="dropdown-menu inner" role="listbox"
                                                        aria-expanded="false">
                                                        <li data-original-index="0" className="selected"><a tabIndex="0"
                                                                                                            className=""
                                                                                                            data-tokens="null"
                                                                                                            role="option"
                                                                                                            aria-disabled="false"
                                                                                                            aria-selected="true"><span
                                                            className="text">Sort By</span><span
                                                            className="glyphicon glyphicon-ok check-mark"></span></a>
                                                        </li>
                                                        <li data-original-index="1"><a tabIndex="0" className=""
                                                                                       data-tokens="null" role="option"
                                                                                       aria-disabled="false"
                                                                                       aria-selected="false"><span
                                                            className="text">A to Z</span><span
                                                            className="glyphicon glyphicon-ok check-mark"></span></a>
                                                        </li>
                                                        <li data-original-index="2"><a tabIndex="0" className=""
                                                                                       data-tokens="null" role="option"
                                                                                       aria-disabled="false"
                                                                                       aria-selected="false"><span
                                                            className="text">Z- A</span><span
                                                            className="glyphicon glyphicon-ok check-mark"></span></a>
                                                        </li>
                                                        <li data-original-index="3"><a tabIndex="0" className=""
                                                                                       data-tokens="null" role="option"
                                                                                       aria-disabled="false"
                                                                                       aria-selected="false"><span
                                                            className="text">High to Low</span><span
                                                            className="glyphicon glyphicon-ok check-mark"></span></a>
                                                        </li>
                                                        <li data-original-index="4"><a tabIndex="0" className=""
                                                                                       data-tokens="null" role="option"
                                                                                       aria-disabled="false"
                                                                                       aria-selected="false"><span
                                                            className="text">Low to High</span><span
                                                            className="glyphicon glyphicon-ok check-mark"></span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <select className="selectpicker" tabIndex="-98">
                                                    <option>Sort By</option>
                                                    <option>A to Z</option>
                                                    <option>Z- A</option>
                                                    <option>High to Low</option>
                                                    <option>Low to High</option>
                                                </select></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="home">
                                            {  this.state.loading && [0, 1, 2, 3,0, 1, 2, 3,0, 1, 2, 3].map((value,index) => (
                                                <div className="col-md-4 col-sm-4 mb-30" key={index}>
                                                    <div className="product-box">
                                                        <div className="product-img">
                                                <span>
                                                    {/*<img src="img/product/wait-content-produto.jpg" alt=""/>*/}
                                                    <ShimmerThumbnail width={260} height={315} rounded/>
                                                </span>

                                                            <div className="action-box">
                                                                {/*<a ><i className="ion-bag"></i></a>*/}
                                                                {/*<a ><i className="ion-ios-search-strong"></i></a>*/}
                                                                {/*<a ><i className="ion-heart"></i></a>*/}
                                                            </div>
                                                        </div>
                                                        <div className="product-content text-center">
                                                            {/*<h3 className="product-title"><a >Medi Product Title</a></h3>*/}
                                                            {/*<div className="product-price">*/}
                                                            {/*    <span>$50</span>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }


                                            {
                                                this.state.loading===false && this.state.resultadoPesquisaList.length===0 &&
                                                    <div className="col-md-4 col-sm-4 mb-30 text-center" >
                                                        <strong>
                                                            Sem resultados na pesquisa
                                                        </strong>
                                                    </div>
                                            }

                                            {
                                                this.state.loading===false && this.state.resultadoPesquisaList.length>0 && this.state.resultadoPesquisaList.map(produtoEstoque => (
                                                    <div className="col-md-4 col-sm-4 mb-30" key={produtoEstoque.id}>
                                                        <ProdutoEstoqueUiComponent produtoEstoque={produtoEstoque}  />
                                                    </div>

                                                ))
                                            }

                                            {/* PAGINADOR */}
                                            {
                                                this.state.loading===false && this.state.resultadoPesquisaList.length<this.state.totalEncontradoPesquisa  &&
                                                <div className="col-sm-12 text-center mt-20">
                                                    <nav>
                                                        <button className='btn btn-default' onClick={()=>{

                                                            this.state.offset = this.state.resultadoPesquisaList.length;

                                                            this.pesquisarTermo(true)

                                                        }}>carregar mais resultados</button>
                                                    </nav>
                                                </div>
                                            }

                                            {
                                                this.state.loading===false &&  this.state.totalPages>1 &&
                                                <div className="col-sm-12 hide">
                                                    <nav>
                                                        <ul className="pagination">
                                                            <li><a href="#" aria-label="Previous"><i className="ion-ios-arrow-back"></i></a></li>
                                                            <li className="active"><a href="#">1</a></li>
                                                            <li><a href="#">2</a></li>
                                                            <li><a href="#">3</a></li>
                                                            <li><a href="#">4</a></li>
                                                            <li><a href="#">5</a></li>
                                                            <li><a href="#" aria-label="Next"><i
                                                                className="ion-ios-arrow-forward"></i></a></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            }

                                        </div>

                                        <div role="tabpanel" className="tab-pane " id="profile">
                                            <div className="col-md-12 col-sm-12 shop-list">
                                                <div className="product-box mb-30">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                                        <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                        <p>Pellentesque rutrum dictum ex, ac rutrum libero vulputate id.
                                                            Pellentesque et risus mattis, congue metus vel, pulvinar
                                                            orci. Phasellus dictum dui ut orci tincidunt, quis tincidunt
                                                            nisl lobortis. In a dictum quam. Aenean cursus leo ante.</p>
                                                        <div className="action-box">
                                                            <a href="#"><i className="ion-bag"></i></a>
                                                            <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                            <a href="#"><i className="ion-heart"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-box mb-30">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                                        <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                        <p>Pellentesque rutrum dictum ex, ac rutrum libero vulputate id.
                                                            Pellentesque et risus mattis, congue metus vel, pulvinar
                                                            orci. Phasellus dictum dui ut orci tincidunt, quis tincidunt
                                                            nisl lobortis. In a dictum quam. Aenean cursus leo ante.</p>
                                                        <div className="action-box">
                                                            <a href="#"><i className="ion-bag"></i></a>
                                                            <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                            <a href="#"><i className="ion-heart"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-box mb-30">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                                        <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                        <p>Pellentesque rutrum dictum ex, ac rutrum libero vulputate id.
                                                            Pellentesque et risus mattis, congue metus vel, pulvinar
                                                            orci. Phasellus dictum dui ut orci tincidunt, quis tincidunt
                                                            nisl lobortis. In a dictum quam. Aenean cursus leo ante.</p>
                                                        <div className="action-box">
                                                            <a href="#"><i className="ion-bag"></i></a>
                                                            <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                            <a href="#"><i className="ion-heart"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-box mb-30">
                                                    <div className="product-img">
                                                        <a href="product-details.html"><img src="img/product/5.jpg" alt="" /></a>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3 className="product-title"><a href="product-details.html">Medi Product Title</a></h3>
                                                        <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <div className="product-price">
                                                            <span>$39</span>
                                                            <span className="price-old">$50</span>
                                                        </div>
                                                        <p>Pellentesque rutrum dictum ex, ac rutrum libero vulputate id.
                                                            Pellentesque et risus mattis, congue metus vel, pulvinar
                                                            orci. Phasellus dictum dui ut orci tincidunt, quis tincidunt
                                                            nisl lobortis. In a dictum quam. Aenean cursus leo ante.</p>
                                                        <div className="action-box">
                                                            <a href="#"><i className="ion-bag"></i></a>
                                                            <a href="#"><i className="ion-ios-search-strong"></i></a>
                                                            <a href="#"><i className="ion-heart"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <nav>
                                                    <ul className="pagination">
                                                        <li><a href="#" aria-label="Previous"><i className="ion-ios-arrow-back"></i></a></li>
                                                        <li className="active"><a href="#">1</a></li>
                                                        <li><a href="#">2</a></li>
                                                        <li><a href="#">3</a></li>
                                                        <li><a href="#">4</a></li>
                                                        <li><a href="#">5</a></li>
                                                        <li><a href="#" aria-label="Next"><i className="ion-ios-arrow-forward"></i></a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )

    }
}

export default Pesquisar
